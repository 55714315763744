<template>
  <!-- 对于需要传入slot的场景适配 主要用于商卡补图场景 -->
  <div 
    class="transform-img-container"
    :class="{
      'loading': !imgLoadingCompleted
    }"
  >
    <picture v-if="disPlayMode === 'picture'">
      <source
        :srcset="baseCutImg"
        type="image/webp"
      />
      <img
        :class="imgClassName"
        :style="imgStyle"
        :src="normalCutImg"
        :fetchpriority="fetchpriority"
        :alt="alt"
        @load="imgLoad"
        @error="imgError"
      />
    </picture>
    <img
      v-else-if="disPlayMode === 'forceImg'"
      :class="imgClassName"
      :style="imgStyle"
      :src="baseCutImg"
      :fetchpriority="fetchpriority"
      :alt="alt"
      @load="imgLoad"
      @error="imgError"
    />
    <img
      v-else
      class="lazyload"
      :class="imgClassName"
      :style="imgStyle"
      :src="loadImg"
      :data-src="imgSrc"
      :data-design-width="imgDesignWidth"
      :data-exp="imgDataExpStr"
      :fetchpriority="fetchpriority"
      @load="imgLoad"
      @error="imgError"
    />

    <slot></slot>
  </div>
</template>

<script>
import { EmptyImg, imgExpandData2Str } from 'public/src/services/resource/index'
export default {
  name: 'TransformImg',
  props: {
    /**
     * @param {String} imgSrc 图片原始地址
     * @param {Boolean} usePicture 是否使用picture标签渲染图片
     * @param {Boolean} isFirstPage 是否首屏(首屏使用src直出图片 非首屏使用lazyload方式加载图片)
     * @param {String} alt 资源描述信息
     * @param {String} loadImg 站位图不传则使用1px*1px灰底图
     * @param {Object} imgStyle 图片样式
     * @param {Boolean} useOriginImg 使用imgSrc原图地址
     * @param {String} imgClassName className
     * @param {String} imgDesignWidth 图片(750)设计稿宽度
     * @param {Object} imgDataExp 图片扩展信息
     * */
    imgSrc: {
      type: String,
      default: '',
    },
    usePicture: {
      type: Boolean,
      default: false,
    },
    isFirstPage: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: '',
    },
    imgStyle: {
      type: Object,
      default: () => ({}),
    },
    loadImg: {
      type: String,
      default: '',
    },
    useOriginImg: {
      type: Boolean,
      default: false,
    },
    imgClassName: {
      type: [String, Array],
      default: '',
    },
    imgDesignWidth: {
      type: [Number, String],
      default: 0,
    },
    imgDataExp: {
      type: Object,
      default: () => ({}),
    },
    fetchpriority: {
      type: String,
      default: '',
    }
  },
  inject: {
    cutImg: {
      from: 'cutImg',
      default: (url) => url,
    },
  },
  data() {
    return {
      disPlayMode: '',
      EmptyImg,
      imgLoadingCompleted: false
    }
  },
  computed: {
    imgDataExpStr() {
      return imgExpandData2Str(this.imgDataExp)
    },
    baseCutImg() {
      // 没有cutImg方法或者使用原图时，不裁剪  
      if (!this?.cutImg || this.useOriginImg) {
        return this.imgSrc.replace(/^https?\:/, '').replace(/\b(\.jpg|\.gif|\.png)\b/, '.webp')
      }
      return this.cutImg(this.imgSrc, this.imgDesignWidth, this.imgDataExp)
    },
    normalCutImg() {
      const url = this.imgSrc
      const idx = url.lastIndexOf('.')
      const suffix = url.slice(idx)
      const baseUrl = this.baseCutImg
      const baseSuffix = baseUrl.slice(idx)
      return baseUrl.replace(baseSuffix, suffix)
    }
  },
  created() {
    const disPlayModeTypeMap = {
      picture: this.usePicture && this.isFirstPage,
      forceImg: !this.usePicture && this.isFirstPage
    }

    this.disPlayMode = Object.keys(disPlayModeTypeMap).find((key) => {
      return disPlayModeTypeMap[key]
    }) ?? ''

    // 有懒加载图并且用到懒加载才会执行
    if (!this.loadImg || this.disPlayMode) {
      this.imgLoadingCompleted = true
    }
  },
  methods: {
    imgLoad(e) {
      // 不使用图片懒加载
      if (this.imgLoadingCompleted) {
        this.$emit('imgLoadSucc', e)

        return false
      }

      // 使用图片懒加载
      const loadImg = this.loadImg
      // 等待真正图片加载后显示补图蒙层
      if (loadImg && e.target.src && !e.target.src.includes(loadImg)) {
        this.imgLoadingCompleted = true

        this.$emit('imgLoadSucc', e)
      }
    },
    imgError(e) {
      this.$emit('imgLoadFail', e)
    }
  }
}
</script>

<style lang="less">
.transform-img-container {
  &.loading {
    img {
      object-fit: cover;
    }
    .crop-image-container__mask {
      background-color: transparent;
    }
  }
}
</style>
  
  